import './form'
import './popup'
import './ui-elements'

$('.cat-item .more-link').on("click",function(e){
	e.preventDefault();
	$(this).toggleClass('opened');
	$(this).parent().parent().find('.hidden').toggleClass('d-none');  
}); 

	if($("div").is(".product-main-init")){ 
		var reviewsslider = new Swiper('.js-reviews-swiper', {
			spaceBetween: 0,		
			slidesPerView: 1,
			navigation: {
				nextEl: '.pm-next',
				prevEl: '.pm-prev',
			},
		});
	}

	if($("div").is(".js-banner-slider")){ 
		var bannerslider = new Swiper('.js-banner-slider', {
			spaceBetween: 0,		
			slidesPerView: 1,
			autoplay: {
				delay: 4000,
			},
			navigation: {
				nextEl: '.b-next',
				prevEl: '.b-prev',
			},
		});
	}

	if (($(".js-product-slider-init").length > 0 )) {			
		$('.js-product-slider-init').each(function(){
				var main = new Swiper(this.querySelector('.js-product-slider'), {
					loop: true,
					noSwiping: false,
					spaceBetween: 20,
					watchSlidesVisibility: true,
					slidesPerView: 3,
					navigation: {
							nextEl: this.querySelector('.p-next'),
							prevEl: this.querySelector('.p-prev'),
					},
					autoplay: {
						delay: 5000,
					},
					breakpoints: {
						992: {
							slidesPerView: 3,
						},
						768: {
							slidesPerView: 2,
						},
						580: {
							slidesPerView: 1,
						},
					},
				});
		});				
	}


	$('.js-pricelist-select').on("change",function(){
		let term_id=$(this).find(':selected').data('id');
		let data = {
			action: 'get_pricelist',
			term_id: term_id,					
		} 		
		console.log(data);
		$.ajax({
			url: '/wp-admin/admin-ajax.php',
			data: data,
			type: 'POST', 
			// dataType: "json",
			error: function (jqXHR, exception) {
				var msg = '';
				alert(exception);			
			},
			beforeSend: function(){},
			success: function( data ) {		
				$('.js-pricelist-results').html(data);
					console.log(data);

			}
		});	
	});


	if($("div").is(".product-main-init")){ 
		var galleryThumbs = new Swiper('.product-thumb-init', {
			spaceBetween: 20,		
			slidesPerView: 4,
			freeMode: true,	
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			breakpoints: {
				1300: {
				spaceBetween: 10
				}
			},
			navigation: {
				nextEl: '.thumb-next',
				prevEl: '.thumb-prev',
			},
		});
		var galleryTop = new Swiper('.product-main-init', {
			spaceBetween: 0,	
			slidesPerView: 1,		
			thumbs: {
				swiper: galleryThumbs
			}
		});
	}

	$('.js-nav-btn').on('click',function(){	
		var $this = $(this);
		var navBlock = $('.js-nav-block');
		$this.toggleClass('burger_active');
		navBlock.slideToggle();
		$('.header-col--nav').toggleClass('active'); 
	
	});

	

$(document).on("click",".js-add-to-card", function(e) {
		e.preventDefault(); 
		let $thisbutton = $(this);
	
		let product_qty = $thisbutton.data("quantity");
		let product_id = $thisbutton.data("product_id");
		let variation_id = $thisbutton.data("variation_id");

		let data = {
				action: 'ql_woocommerce_ajax_add_to_cart',
				product_id: product_id,
				product_sku: '',
				quantity: product_qty,
				variation_id : variation_id
			};
			console.log(data);
		$.ajax({
				type: 'post',
				url: '/wp-admin/admin-ajax.php',
				data: data,
				beforeSend: function (response) {
					$thisbutton.removeClass('added').addClass('loading');
				},
				complete: function (response) {
					$thisbutton.addClass('added').removeClass('loading');
				}, 
				success: function (response) { 
					if (response.error & response.product_url) {
						window.location = response.product_url;
						return;
					} else { 
						$(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash, $thisbutton]);
						console.log(response);
						$('.basket-result').html(response.fragments.basket);
						$('.added-info').html(response.fragments.add_to_card_notification);
						$('.added-info').animate({"right":"0px"});
						// $('.basket-modal').html(response.fragments.modal_card);
						setTimeout(function(){
							$('.added-info').animate({"right":"-200px"});
						},3000);
						$thisbutton.html('Добавлено');
					} 
				}, 
	}); 




}); 


$(document).on("click",".js-add-to-fav",function (e) {
	e.preventDefault();  	
	let $item=$(this);
	var postid = $(this).data('postid');		
	var type = $(this).data('type');
	$item.toggleClass('added');	
	if ($item.hasClass('added')) {
		localStorage.setItem ( postid,
		JSON.stringify(
		{
		 'added': true,
		 'type' : type
		}
		));
	} 		
	else {
		localStorage.removeItem(postid); 
	}
	if($(this).closest('.favblock').length){
		$(this).closest('.col-sm-6').remove();
	}		
	set_fav_links();
});


// $(document).on("click",".js-remove-compare",function (e) {
// 	var postid = $(this).data('postid');
// 	localStorage.removeItem(postid); 
// 	$(this).closest('.col-md-6').remove();
// });

function set_fav_links(){
	if (typeof(localStorage) == 'undefined') {
		document.getElementById("result").innerHTML =
			'';
		} else {
		let fav_count=0;
		for(let i=0; i<localStorage.length; i++) {
			let key = localStorage.key(i);			
			if(parseInt(key)) {
				let object= JSON.parse(localStorage.getItem(key));	
				if(object.type=="product") {
					fav_count++;
				}
			}							
		}							
		if(fav_count != 0) {
			$('.fav-notificate').removeClass('hidden');
			$('.js-fav-count').text(fav_count);
		} else {
			$('.js-fav-count').text('');
			$('.fav-notificate').addClass('hidden');
		}
	}
}

set_fav_links();

if($('.js-fav-results').length) {
	let products=[];
	for(let i=0; i<localStorage.length; i++) {
		let key = localStorage.key(i);							 
		let object= JSON.parse(localStorage.getItem(key));				
		if(object.type=="product") {
			products.push(key);
		}
	}		
	let data = {
		action: 'loadfavorites',
		products: products,					
	} 	
	console.log(data);
	$.ajax({
		url: '/wp-admin/admin-ajax.php',
		data: data,
		type: 'POST', 
		error: function (jqXHR, exception) {
			var msg = '';
			alert(exception);			
		},
		beforeSend: function(){},
		success: function( data ) {		
			$('.js-fav-results').html(data);
			console.log(data);
			setadded();
		}
	});	
}	



function setadded(){

	if (typeof(localStorage) == 'undefined') {
		document.getElementById("result").innerHTML =
			'Your browser does not support HTML5 localStorage. Try upgrading.';
		} else {		

			$(".fav-btn").each(function(i, el) {				
				let object = JSON.parse(localStorage.getItem($(this).data('postid')));
				if(object) {
					if (object.added == true) {				
						$(this).addClass('added');
						}
				}
				});


		}
}	

setadded();

$(window).scroll(function(){
	var aTop = 0;
	var navblock= $('header'); 
	if($(this).scrollTop()>aTop){
		navblock.addClass('fixed');
	} else{
		navblock.removeClass('fixed');
	}
});


$(window).scroll(function(){
	if($(window).width()>=992) {
		var aTop = $('header').height(); 
		var navblock= $('.stick-data'); 
		if($(this).scrollTop()>aTop && $(this).scrollTop()<$('.product-row').height()){
			navblock.addClass('fixed');
		} else{
			navblock.removeClass('fixed');
		}
	}
});

$('.accordion-items .accordion-item__header').on("click",function(e){
	e.preventDefault();
	$(this).toggleClass("active");		
	$('.accordion-items .accordion-item__header').not(this).parent().find('.accordion-item__body').slideUp();	
	$(this).parent().find('.accordion-item__body').slideToggle();
});

Fancybox.bind("[data-fancybox]", {});



$('.catalog').on('click',function(e){	

	if($(window).width()<=992) {
		e.preventDefault();
		e.stopPropagation();    
		$(this).toggleClass("opened");
		if(e.target.tagName!="A" ) {
			$(this).find('>ul.sub-menu').slideToggle();
		}
	} else {
		
		// $(this).find('>ul.sub-menu').slideToggle();
	 // $('.catalog-menu').slideToggle();
	}
});


$('.catalog li.menu-item-has-children').on('click',function(e){	
	if($(window).width()<=992) {
		e.stopPropagation();    
		$(this).toggleClass("opened");
		if(e.target.tagName!="A" ) {
			let this_sub_menu=$(this).find('>ul.sub-menu');
			this_sub_menu.slideToggle();		
		}
	}
	// e.preventDefault();
	// var $this = $(this);
	//  $this.parent().find('>ul.sub-menu').toggleClass('opened');
	// // $('.catalog-menu').slideToggle();
});

$('.aside-menu li.menu-item-has-children').on('click',function(e){	
	e.stopPropagation();    
	$(this).toggleClass("opened");
	if(e.target.tagName!="A" ) {
		let this_sub_menu=$(this).find('>ul.sub-menu');
		$('.aside-menu ul.sub-menu').not(this_sub_menu).slideUp();
		this_sub_menu.slideToggle();		
	}
});

$(document).on("click",".rate-items .rate-item",function (e) {
	e.preventDefault();
	var index = $(this).index();
	$(this).closest('.rate-items').find('.rate-item').removeClass("active");
	$(this).closest('.rate-items').find(".rate-item:lt("+index+")").addClass("active");
	$(this).addClass('active');
	$('input[name=rate]').val(index+1);
});


$B.on('click', '.js-tab__item', function (e) {
	e.preventDefault();
	let $link = $(this);
	let id = $link.attr('data-href');
	$('.js-tab__item').removeClass('active');
	$('.js-tab__block').fadeOut();
	$(this).addClass('active');
	$("."+id).fadeIn();
});



$('.card-templates a').on("click",function(e){
	e.preventDefault();
	$('.card-templates a').removeClass("active");
	$(this).addClass("active");	
	filter();  
});


function get_idarray(inputs) {	
  let temparray=new Array();	
  inputs.each(function (index, el){
    var value  = $(el).val();		
    if (value) temparray.push(value);
  });		
  return temparray; 			
}

$(document).on("change", ".js-select-sort", function (e) {    
  filter();
});

function filter(){
  
  let product_cat=$('.filter-sort').attr('data-cyr_cat');	 
  // let product_type=get_idarray($('.product_type .form-checkbox__input:checked'));
  // let product_brand=   get_idarray($('.product_brand .form-checkbox__input:checked'));
  // let product_size=  get_idarray($('.product_size .form-checkbox__input:checked'));
  // let	order_by=$('.catalog-top a.active').data('order');	
  // let direction=$('.catalog-top a.active').attr('data-direction');	
  // let	order_by=$('.js-select-sort a.active').data('order');	

	let order_by=$('.js-select-sort').find(':selected').data('order');
  let direction=$('.js-select-sort').find(':selected').data('direction');
  let template=$('.card-templates .active').attr('data-type');	

  // order_by=$('.filter-section_order input[type="radio"]:checked').val();
  let data = {
    action: "productfilter",
    order_by: order_by,
    direction: direction,
		template: template,
		product_cat: product_cat
  } 			
  console.log(data);
  $.ajax({
    url: '/wp-admin/admin-ajax.php',
    data: data,
    type: 'POST',
    beforeSend: function(){				
    },  
    success: function(data){      
      $('.js-product-result').html(data); 
			$('.pagination').hide();
    }
  });
}


// $.fn.textAnimation = function( animation_speed, text_speed, animation ){
// 	var text, i = 0;
// 	var $this = $(this);	
// 	// store text and clear
// 	text = $this.text();
// 	$this.css('white-space', 'pre');
// 	$this.html('');	
// 	var addLetter = setInterval(function(){
// 		$this.append('<div class="text_animation" style="display: inline-block; animation: ' + animation + ' ' + animation_speed + 'ms forwards">' + text[i] + '</div>');
// 		i++;
// 		if (i == text.length) clearInterval(addLetter);
// 	}, text_speed);
// }

// var temp = $('.bounce_in_animation').text();

// var i = 1;

// $('.bounce_in_animation').textAnimation(400, 500, 'bounceIn');




$('.faq-item .faq-item__header').click(function(event){
	event.preventDefault();
	$(this).next().slideToggle();
	$(this).toggleClass('is-collapsed');
});

let month =['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
let days = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

$('.js-date-init').each(function(){ 
	var contradate_item = datepicker(this,{ 
		customOverlayMonths: month,
		customMonths: month,
		customDays: days,
		minDate: new Date(),
		formatter: (input, date, instance) => {
			const value = date.toLocaleDateString()
			input.value = value // => '1/1/2099'
		}
	});
});